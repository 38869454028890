// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-nurofen-src-templates-article-listing-page-article-listing-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ArticleListingPage/ArticleListingPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-article-listing-page-article-listing-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-article-page-article-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ArticlePage/ArticlePage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-article-page-article-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-children-dosage-children-dosage-information-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ChildrenDosage/ChildrenDosageInformation.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-children-dosage-children-dosage-information-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-contact-us-page-contact-us-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ContactUsPage/ContactUsPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-contact-us-page-contact-us-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-error-404-page-error-404-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/Error404Page/Error404Page.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-error-404-page-error-404-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-lds-page-lds-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/LdsPage/LdsPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-lds-page-lds-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-meltlets-landing-page-meltlets-landing-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/MeltletsLandingPage/MeltletsLandingPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-meltlets-landing-page-meltlets-landing-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-product-list-page-product-list-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ProductListPage/ProductListPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-product-list-page-product-list-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-product-page-product-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-product-page-product-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-search-page-search-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/SearchPage/SearchPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-search-page-search-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-sitemap-page-sitemap-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/SitemapPage/SitemapPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-sitemap-page-sitemap-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-symptoms-page-symptoms-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/SymptomsPage/SymptomsPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-symptoms-page-symptoms-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-useful-links-page-useful-links-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/UsefulLinksPage/UsefulLinksPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-useful-links-page-useful-links-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-where-to-buy-page-where-to-buy-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/WhereToBuyPage/WhereToBuyPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-where-to-buy-page-where-to-buy-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-why-nurofen-page-why-nurofen-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/WhyNurofenPage/WhyNurofenPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-why-nurofen-page-why-nurofen-page-tsx" */),
  "component---src-gatsby-theme-nurofen-templates-campaign-page-campaign-page-tsx": () => import("./../../../src/gatsby-theme-nurofen/templates/CampaignPage/CampaignPage.tsx" /* webpackChunkName: "component---src-gatsby-theme-nurofen-templates-campaign-page-campaign-page-tsx" */),
  "component---src-gatsby-theme-nurofen-templates-children-hub-page-children-hub-page-tsx": () => import("./../../../src/gatsby-theme-nurofen/templates/ChildrenHubPage/ChildrenHubPage.tsx" /* webpackChunkName: "component---src-gatsby-theme-nurofen-templates-children-hub-page-children-hub-page-tsx" */),
  "component---src-gatsby-theme-nurofen-templates-home-page-home-page-tsx": () => import("./../../../src/gatsby-theme-nurofen/templates/HomePage/HomePage.tsx" /* webpackChunkName: "component---src-gatsby-theme-nurofen-templates-home-page-home-page-tsx" */),
  "component---src-gatsby-theme-nurofen-templates-pain-campaign-page-pain-campaign-page-tsx": () => import("./../../../src/gatsby-theme-nurofen/templates/PainCampaignPage/PainCampaignPage.tsx" /* webpackChunkName: "component---src-gatsby-theme-nurofen-templates-pain-campaign-page-pain-campaign-page-tsx" */),
  "component---src-gatsby-theme-nurofen-templates-sign-up-page-sign-up-page-tsx": () => import("./../../../src/gatsby-theme-nurofen/templates/SignUpPage/SignUpPage.tsx" /* webpackChunkName: "component---src-gatsby-theme-nurofen-templates-sign-up-page-sign-up-page-tsx" */)
}

